import converter from 'number-to-words'

const numberOfYearsSince = function(startDateString) {
  const startDate = new Date(startDateString)
  const todaysDate = new Date()
  return converter.toWords(todaysDate.getFullYear() - startDate.getFullYear())
}

const Summary = ({ data, htmlParser }) => {
  const summaryData = data.allSummaryJson.nodes[0]

  return (
    <div>
      <h1>{summaryData.name}</h1>
      <div className="section">
        <span className="headline">{summaryData.careerTitle}</span>
        <p>
          <span className="grey">{summaryData.currentLocation}</span>
        </p>

        <span className="grey">
          <p>
            <span style={{ textTransform: 'capitalize' }}>{numberOfYearsSince(summaryData.careerStartDate)}</span> years
            in software development and project management. {summaryData.introText}
          </p>

          {htmlParser.parse(summaryData.itemizedAccomplishments)}
        </span>
      </div>
    </div>
  )
}

export default Summary

import moment from 'moment'

const timeRangeDescription = function(job) {
  const endDate = job.isCurrent ? new Date() : job.date.end
  const rangeInMonths = moment(endDate).diff(job.date.start, 'month')
  const fullYears = Math.floor(rangeInMonths / 12)
  const remainderMonths = rangeInMonths % 12
  const yearsDescription = fullYears > 0 ? `${fullYears} year${fullYears > 1 ? 's' : ''} ` : ''
  const monthsDescription = remainderMonths > 0 ? `${remainderMonths} month${remainderMonths > 1 ? 's' : ''}` : ''
  return `${yearsDescription} ${monthsDescription}`
}

const renderJob = function(job, htmlParser) {
  const jobStart = moment(job.date.start).format('MMM YYYY')
  const jobEnd = job.isCurrent ? 'Current' : moment(job.date.end).format('MMM YYYY')

  return (
    <div className="section">
      <p>
        <a target="_blank" rel="noopener noreferrer" href={job.companyUrl}>
          <span className="headline">{job.companyName}</span>
        </a>
      </p>

      <p>
        <span className="grey">
          {job.jobTitle} | {jobStart} – {jobEnd}, {timeRangeDescription(job)}
        </span>
      </p>

      {htmlParser.parse(job.descriptionHtml)}
    </div>
  )
}

const Experience = ({ data, htmlParser }) => {
  const experience = data.allExperienceJson
  return (
    <div>
      <h2>EXPERIENCE</h2>
      {experience.nodes.map(job => renderJob(job, htmlParser))}
    </div>
  )
}

export default Experience

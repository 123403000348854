import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import { Parser } from 'html-to-react'
import React from 'react'
import Layout from '../components/Layout'
import Summary from '../components/Summary'
import Experience from '../components/Experience'
import Projects from '../components/Projects'
import Education from '../components/Education'
import Skills from '../components/Skills'

const containerStyles = css`
  margin: 0 auto;
  padding: 4rem 2rem 8rem 2rem;
  max-width: 600px;
  color: #333333;

  h1 {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  h2 {
    margin-top: 4rem;
    font-size: 12px;
    font-weight: 600;
    color: #c9cccf;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  p {
    margin-bottom: 0.25rem;
  }

  a {
    transition: box-shadow 160ms ease 0s;
    box-shadow: rgb(238, 251, 255) 0px -9px 0px inset;
    border-bottom: 2px solid rgb(207, 243, 255);
    text-decoration: none;
    color: #323336;
    &:hover {
      box-shadow: rgb(207, 243, 255) 0px -1.2em 0px inset;
    }
  }

  .section ul {
    margin-top: 0.5rem;
    margin-left: 2.5rem;
    list-style: disc;
  }

  .section li {
    margin-bottom: 0.5rem;
  }

  .grey {
    color: #96999b;
  }

  .headline {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1.2px;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
  }

  .skills ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  .skills li {
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    padding: 0.25rem 1rem;
    background-color: #f1f5f7;
    white-space: nowrap;
  }
`

const htmlToReactParser = new Parser()

export default props => {
  const { data } = props

  return (
    <Layout>
      <div css={containerStyles}>
        <Summary data={data} htmlParser={htmlToReactParser} />
        <Experience data={data} htmlParser={htmlToReactParser} />
        <Education data={data} />
        <Skills data={data} />
        <Projects data={data} htmlParser={htmlToReactParser} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allSummaryJson(limit: 1) {
      nodes {
        name
        careerStartDate
        careerTitle
        currentLocation
        introText
        itemizedAccomplishments
      }
    }
    allExperienceJson(sort: { fields: date___start, order: DESC }) {
      nodes {
        companyName
        isCurrent
        jobTitle
        date {
          end
          start
        }
        descriptionHtml
      }
    }
    allProjectsJson {
      nodes {
        name
        url
        descriptionHtml
      }
    }
    allEducationJson(sort: { order: ASC, fields: dates___start }) {
      nodes {
        dates {
          end
          start
        }
        degreeName
        institutionName
      }
    }
    allSkillsJson {
      nodes {
        name
      }
    }
  }
`

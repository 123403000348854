const Skills = ({ data }) => {
  const skills = data.allSkillsJson
  return (
    <div className="skills">
      <h2>SKILLS</h2>
      <ul>
        {skills.nodes.map(skill => (
          <li>{skill.name}</li>
        ))}
      </ul>
    </div>
  )
}

export default Skills

import moment from 'moment'

const Education = ({ data }) => {
  const allEducation = data.allEducationJson
  return (
    <div>
      <h2>EDUCATION</h2>
      {allEducation.nodes.map(education => {
        const start = moment(education.dates.start).format('MMM YYYY')
        const end = moment(education.dates.end).format('MMM YYYY')
        return (
          <div className="section">
            <p>
              <span className="headline">{education.institutionName}</span>
              <span className="grey">
                {education.degreeName} | {start} - {end}
              </span>
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default Education

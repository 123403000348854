const renderProject = function(project, htmlParser) {
  return (
    <div className="section">
      <p>
        <a target="_blank" rel="noopener noreferrer" href={project.url}>
          <span className="headline">{project.name}</span>
        </a>
        {htmlParser.parse(project.descriptionHtml)}
      </p>
    </div>
  )
}

const Projects = ({ data, htmlParser }) => {
  const projects = data.allProjectsJson
  return (
    <div>
      <h2>PROJECTS</h2>
      {projects.nodes.map(project => renderProject(project, htmlParser))}
    </div>
  )
}

export default Projects
